<template lang="html">
  <div class="">
    <div class="main-container">
      <div class="h2">
        <button class="btn-back" type="button" name="button" @click="$router.go(-1)">
          <span class="icon-w-back"></span>
        </button>
        修改头像
      </div>
      <!-- <div class="" v-if="userInfo">
        <div class="info-avatar">
          <img :src="userInfo.avatar + '?x-oss-process=style/avatar'" alt="">
        </div>
      </div> -->
      <div class="">
        <el-upload
          class="avatar-uploader"
          action="https://api.wanfangche.com/community/public/common/upload"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload">
          <img v-if="imageUrl" :src="imageUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </div>
      <div class="btn-group m-t">
        <button type="button"
        :disabled="disabled"
        @click="onSubmit"
        :class="{'btn-success': !disabled}"
        class="btn btn-">
          使用此头像
        </button>
      </div>
      <div class="success-msg m-t" v-if="message">
        {{message}}
      </div>
      <div class="error-msg m-t" v-if="err">
        {{err}}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { AccountFactory } from "../../factory/AccountFactory";

export default {
  data() {
    return {
      message: '',
      err: '',
      imageUrl: "",
      disabled: true,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      if (res) {
        this.imageUrl = res.data[0].filename;
      }
      this.disabled = false;
    },
    beforeAvatarUpload(file) {
      this.disabled = true;
      this.message = "";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    // 需要快速完成，所以 没做更多封装
    onSubmit() {
      this.message = "";
      this.err = "";
      const data = {
        avatar: this.imageUrl,
      };
      AccountFactory.updateProfile(data)
        .then((res) => {
          if (res) {
            this.message = "修改成功";
          }
        })
        .catch((e) => {
          this.err = e.message;
        });
    },
  },
  watch: {
    userInfo(val) {
      console.log(val.avatar);
    },
  },
};
</script>

<style lang="css">
.info-avatar {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 4px 4px;
}
.info-avatar > img {
  width: 100%;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
</style>
